<template >    
<div>
</div> 
</template>
<script>

export default {
      name: "App",
  
    data() {
        return{
        }
     
    },
  
    methods:{
    },
   
     created() {
      window.location="https://www.pgyer.com/KT1l"
    },
  
}
</script>
<style>

</style>